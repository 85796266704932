import React from 'react';
import {
  Grid,
  Container,
  //Button
} from 'semantic-ui-react';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  margin-top: 50px;
  padding-top: 20px;
  min-height: 200px;
  background-color: #333;
  color: #fff;
  margin-bottom: 0;
`;

const Footer = () => {
  // TODO: adjust style
  return (
    <Wrapper>
      <Container>
        <Grid verticalAlign="middle">
          <Grid.Row centered>
            <Grid.Column mobile={12} tablet={8} computer={4} textAlign="left">
              <div style={{ marginBottom: 10 }}>
                {/*
                <LazyLoadImage
                  src="/assets/image/bonny_logo_white.svg"
                  width={120}
                />
                 */}
                 {'Bonny波力-桐乡健佑'}
              </div>
              浙江省桐乡市梧桐街道凤翔东路199号





              
              <br />
              <Link to="#">会员服务与条款</Link>
            </Grid.Column>
            <Grid.Column mobile={12} tablet={8} computer={4}>
              <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                {/*<Button circular color="facebook" icon="facebook" />
                <Button circular color="instagram" icon="instagram" />
                <Button circular color="youtube" icon="youtube" />*/}
              </div>
            </Grid.Column>
            <Grid.Column mobile={12} tablet={8} computer={4}>
              Developed By{' '}
              
              <a
                href="#"
                rel="noopener"
                style={{ color: 'lightgrey' }}
              > 
                Bonny波力-桐乡健佑
              </a>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default Footer;
