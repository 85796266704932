import React, { useEffect, Fragment, lazy, Suspense } from 'react';
import 'semantic-ui-css/semantic.min.css';
import './App.scss';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Redirect,
} from 'react-router-dom';
import NavBar from './layouts/NavBar/NavBar';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from './store/actions/auth.actions';
import LoadingComponent from './layouts/common/LoadingComponent';
import { useAlert } from 'react-alert';
import api from './api/api';

// routes
import PublicOnlyRoute from './routes/PublicOnlyRoute';
import PrivateRoute from './routes/PrivateRoute';
import AdminRoute from './routes/AdminRoute';
import Footer from './layouts/Footer/Footer';
import BackToTop from './layouts/BackToTop/BackToTop';
import { CLEAR_CART } from './store/actions/types';
import Terms from './pages/Terms';

// pages
const Home = lazy(() => import('./pages/Home'));
const Admin = lazy(() => import('./pages/Admin'));
const UserDashboard = lazy(() => import('./pages/UserDashboard'));
const UserOrders = lazy(() => import('./pages/UserOrders'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Login = lazy(() => import('./pages/Login'));
const SignUp = lazy(() => import('./pages/SignUp'));
const Cart = lazy(() => import('./pages/Cart'));
const Shop = lazy(() => import('./pages/Shop'));
const UserOrderDetail = lazy(() => import('./pages/UserOrderDetail'));
const Checkout = lazy(() => import('./pages/Checkout'));

const App = () => {
  const dispatch = useDispatch();
  const authLoading = useSelector(state => state.auth.loading);
  // const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  // const user = useSelector(state => state.auth.user)
  const alert = useAlert();
  const authError = useSelector(state => state.auth.error);

  useEffect(() => {
    const checkConnection = async () => {
      try {
        await api.get('/check');
      } catch (e) {
        alert.error('无法连上伺服器，请检查您的网络连线，或是伺服器正在维修');
      }
    };
    checkConnection();
  }, [alert]);

  useEffect(() => {
    authError.length > 0 && alert.error(authError);
  }, [authError, alert]);

  useEffect(() => {
    dispatch(getCurrentUser(localStorage.getItem('token')));
  }, [dispatch]);

  useEffect(() => {
    // for cart finished order but refreshed
    const ordered = localStorage.getItem('ordered') === 'true';
    if (ordered) {
      localStorage.removeItem('ordered');
      dispatch({
        type: CLEAR_CART,
      });
    }
  }, [dispatch]);

  return (
    <Fragment>
      <Router>
        <NavBar />
        {authLoading ? (
          <LoadingComponent />
        ) : (
          <Suspense fallback={<LoadingComponent />}>
            <Switch>
              <Route exact path="/" component={Home} />
              <PublicOnlyRoute exact path="/login" component={Login} />
              <PublicOnlyRoute exact path="/signup" component={SignUp} />
              <PrivateRoute exact path="/dashboard" component={UserDashboard} />
              <PrivateRoute
                exact
                path="/user_orders/:id"
                component={UserOrderDetail}
              />
              <PrivateRoute exact path="/user_orders" component={UserOrders} />
              <Route exact path="/cart" component={Cart} />
              <PrivateRoute exact path="/checkout" component={Checkout} />
              <Route exact path="/shop/:id" component={Shop} />
              <Route exact path="/terms" component={Terms} />
              <AdminRoute exact path="/admin" component={Admin} />
              <NotFound />
            </Switch>
          </Suspense>
        )}
        <Footer />
      </Router>
      <BackToTop />
    </Fragment>
  );
};

export default App;
